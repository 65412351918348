* {
  font-family: 'Inter', sans-serif;
}

.d3-tip {
  line-height: 1;
  font-weight: bold;
  background: #ffffff;
  color: #1e293b;
  border-radius: 2px;
  pointer-events: none;
  border: 1px solid #cbd5e1;
  width: 465px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  position: absolute;
  z-index: 1000000;
}

/* Creates a small triangle extender for the tooltip */

.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 14px;
  line-height: 1;
  color: #ffffff;
  position: absolute;
  pointer-events: none;
}

/* Northward tooltips */

.d3-tip.n:after,
.d3-tip-black.n:after {
  content: '\25BC';
  margin: -3px 0 0 0;
  top: 100%;
  left: calc(50% - 8px);
  text-align: center;
}

/* Eastward tooltips */

.d3-tip.e:after,
.d3-tip-black.e:after {
  content: '\25C0';
  margin: -4px 0 0 0;
  top: 50%;
  left: -8px;
}

/* Southward tooltips */

.d3-tip.s:after,
.d3-tip-black.s:after {
  content: '\25B2';
  margin: 0 0 1px 0;
  top: -10px;
  left: calc(50% - 8px);
  text-align: center;
}

/* Westward tooltips */

.d3-tip.w:after,
.d3-tip-black.w:after {
  content: '\25B6';
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}

/* NorthWestward tooltips */

.d3-tip.nw:after,
.d3-tip-black.nw:after {
  content: '\25E5';
  margin: -3px 0 0 0;
  top: 100%;
  left: calc(100% - 11.4px);
  text-align: center;
}

/* SouthWestward tooltips */

.d3-tip.sw:after,
.d3-tip-black.sw:after {
  content: '\25E2';
  margin: 0 0 3px 0;
  top: -3%;
  left: calc(100% - 11.4px);
  text-align: center;
}

/* NorthEast tooltips */

.d3-tip.ne:after,
.d3-tip-black.ne:after {
  content: '\25E4';
  margin: -3px 0 0 0;
  top: 100%;
  left: -1.5px;
  text-align: center;
}

/* SouthEast tooltips */

.d3-tip.se:after,
.d3-tip-black.se:after {
  content: '\25E3';
  margin: 0 0 3px 0;
  top: -3%;
  left: -1.5px;
  text-align: center;
}

.tip-header-text {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  flex: 1;
}

.tip-header-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

.tip-header-icon-wrapper .navigateTo {
  font-size: 10px;
  text-align: center;
  margin-left: 8px;
}

.tip-header-icon {
  width: 40px;
  height: 40px;
  background-color: #f6faf8;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.tip-header-icon-disable {
  background-color: #eee;
  border: 1px solid #eee;
  cursor: default !important;
}

.tip-header-icon:hover {
  cursor: pointer;
  background-color: #eff1f0;
}

.tip-icon {
  width: 16px;
  height: 16px;
}

.tip-header {
  border-bottom: 1px solid #e7ecf3;
  padding: 10px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tip-info {
  border-bottom: 1px solid #e7ecf3;
  padding: 10px 14px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  display: flex;
}

.tip-info-item {
  flex: 1;
  white-space: nowrap;
}

.tip-info span {
  color: #1da462;
  font-weight: 600;
}

.view-by {
  padding-top: 12px;
  font-size: 12px;
  line-height: 18px;
  color: #1da462;
  font-weight: 400;
}

.tip-container-body {
  display: flex;
  margin: 0 15px;
  padding: 15px 0 10px;
  border-bottom: 1px solid #e7ecf3;
}

.tip-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  padding-left: 30px;
}

.horizontal {
  flex-direction: row;
  align-items: baseline;
}

.tip-item:first-child {
  padding-left: 0px;
}

.tip-item p {
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
}

.tip-item span {
  color: #29ba74;
  font-size: 18px;
  padding-bottom: 5px;
  line-height: 22px;
}

.tip-item span.list {
  color: #29ba74;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 0px;
}

.tip-container-company {
  padding: 10px 15px;
}

.tip-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
}

.tip-title span {
  font-weight: 400;
  color: #475569;
}
.border-block:not(:first-child) {
  border-top: 1px solid #e7ecf3;
  margin-top: 8px;
  padding-top: 8px;
}

.content-block {
  display: flex;
  flex-wrap: wrap;
}

.content-block span {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #1da462;
}

.content-item {
  width: 50%;
  margin-bottom: 6px;
}

.content-item:last-child {
  width: 100%;
}

.content-title-header {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin: 6px 0 2px;
}

.content-investors {
  display: flex;
  flex-wrap: wrap;
}

.content-investors div {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding-top: 6px;
  width: 50%;
}

.tip-list-company {
  display: flex;
}

.tip-company {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  align-items: center;
  width: 15%;
}

.tip-company:first-child {
  padding-left: 0px;
}

.logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2e8f0;
  width: 62px;
  height: 62px;
  border-radius: 3px;
  border: 1px solid #e2e8f0;
  cursor: pointer;
}

.tip-logo {
  max-width: 62px;
  max-height: 62px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #fff;
}

.tip-text-logo {
  width: 100%;
  height: 100%;
  background-color: #94a3b8;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  border-radius: 3px;
}

.tip-company span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #1e293b;
  padding: 10px 0;
  text-align: center;
  word-wrap: normal;
  max-width: 80px;
}

.text-acquired {
  color: #29ba74;
  font-size: 14px;
  padding-bottom: 5px;
  line-height: 22px;
  cursor: pointer;
}

.tip-text-right {
  text-align: right;
}

.tip-list-company-space-between {
  display: flex;
  justify-content: space-between;
}

.wrapper-fundinground {
  width: 100%;
  overflow-y: scroll;
  max-height: 200px;
  scrollbar-width: thin !important;
  scrollbar-color: #cbd5e1 !important;
}
.wrapper-fundinground::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}
.wrapper-fundinground::-webkit-scrollbar-thumb {
  background: #cbd5e1 !important;
  border-radius: 10px !important;
}

/* Tooltip Donut Chart */
.d3-tip-black {
  padding: 8px 14px;
  background-color: #000;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
  border: 1px solid #000;
  width: auto;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  position: absolute;
  z-index: 1000000;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip-black:after {
  box-sizing: border-box;
  display: inline;
  font-size: 14px;
  line-height: 1;
  color: #000;
  position: absolute;
  pointer-events: none;
}

.tip-container-donut {
  display: flex;
  align-items: center;
}

.tip-color-donut {
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 6px;
}

.tip-text-donut {
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.lineText,
.top-label,
.bar-label,
.axis-label,
.axis-label-x,
.y2.axis text,
.y1.axis text,
.x.axis text,
.xAxisLabelGroup,
.line-chart-label {
  font-size: 14px;
  font-weight: normal;
}

.bar-label,
.bar-label-note,
.lineChart {
  pointer-events: none;
}

.cagr text {
  font-weight: bold;
  fill: #29ba74;
}

.axis-label.x {
  width: 102px;
  height: 50px;
  transform: translate(-7px, -37px);
  text-align: left;
}

.axis-label.y {
  width: 200px;
  height: 40px;
  transform: translate(-50px, -50px);
  text-align: left;
}

.axis-label.y2 {
  text-align: left;
}

.lineText {
  line-height: 22px;
}

.top-label {
  line-height: 18px;
}

.axis-label,
.axis-label-x,
.y2.axis text,
.y1.axis text,
.x.axis text {
  line-height: 18px;
}

.top-label,
.axis-label,
.lineText,
.axis-label-x {
  font-weight: 600;
}

.y2.axis text {
  transform: translateX(9px);
}

.y1.axis text {
  transform: translateX(-9px);
}

.x.axis text {
  transform: translateY(9px);
}

.x.axis .rotation {
  transform: translate(-9px, 18px) rotate(-90deg) !important;
  text-anchor: end;
}

.xAxisLabelGroup {
  font-weight: 600;
  color: #0f172a;
  font-family: 'Inter';
}

.text-bold {
  font-weight: 600;
}

.line-chart-label {
  transform: translate(10px, -10px);
  width: 100px;
  height: 50px;
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .lineText,
  .top-label,
  .bar-label,
  .axis-label,
  .axis-label-x,
  .y2.axis text,
  .y1.axis text,
  .x.axis text,
  .xAxisLabelGroup,
  .line-chart-label {
    font-size: 12px;
  }
}

.small-text {
  font-size: 12px;
}

.bar-label-note {
  fill: #ffffff;
  text-anchor: middle;
}

.barChart:hover,
.top-label:hover {
  cursor: pointer;
}

.bar-label-note-top {
  fill: #1e293b;
  text-anchor: middle;
  font-weight: 600;
  font-size: 12px;
}

.wrapper-svgBody {
  scrollbar-width: thin !important;
  scrollbar-color: #cbd5e1 !important;
}
.wrapper-svgBody::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
.wrapper-svgBody::-webkit-scrollbar-thumb {
  background: #cbd5e1 !important;
  border-radius: 10px !important;
}

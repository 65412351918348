.extraClass {
  font-size: 12px !important;
  opacity: 1 !important;
  font-weight: normal;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
  pointer-events: auto !important;
  max-width: 300px;
  text-align: center;
}

.extraClass:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

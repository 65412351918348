.react-toast-notifications__container {
  z-index: 2147483601 !important;
}

.react-toast-notifications__toast__icon-wrapper {
  display: none;
}

.react-toast-notifications__toast__content {
  padding: '12px 16px';
}

.node {
  cursor: pointer;
}

.node:hover {
  stroke: #000;
  stroke-width: 1.5px;
}

.node--leaf {
  fill: white;
}

.no-data {
  fill-opacity: 0.5;
}

.label {
  font: 18px 'Raleway';
  text-anchor: middle;
  text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff;
}

.node--leaf {
  cursor: not-allowed;
}

.node--root,
.label {
  pointer-events: none;
}

.img-label {
  pointer-events: none;
}

.arc-label {
  font-size: 1em;
  font-weight: 600;
}

.selected-label {
  text-anchor: middle;
  font-weight: 600;
  fill: #29ba74;
}

.companies-label {
  text-anchor: middle;
  font-weight: 300;
  fill: black;
}

.tip-header {
  border-bottom: 1px solid #e7ecf3;
  font-weight: 600;
  padding-bottom: 15px;
  font-size: 14px;
  line-height: 22px;
}

.d3-tip.tip-header {
  color: '#0F172A';
}

.d3-tip.tip-body {
  color: '#1E293B';
}

.tip-body {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 10px 14px;
  min-height: 18px;
  max-width: 450px;
  border-bottom: 1px solid #e7ecf3;
}

.zoom {
  fill: none;
  pointer-events: all;
}

.tip-title-top {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 10px 14px;
}

.tip-top-company {
  padding: 0 14px 10px;
  display: flex;
}

.tip-title-content {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 14px;
}

.text-bottom,
.text-left,
.text-right {
  font-family: 'Inter';
  font-size: 1.2em;
  font-weight: 600;
}

.tip-top-company-space-between {
  padding: 0 14px 10px;
  display: flex;
  justify-content: space-between;
}

.tip-tooltip-sunburst {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: '#fff';
}

.rpv-default-layout__body {
  scrollbar-width: 'thin';
  scrollbar-color: #cbd5e1;
}

.rpv-default-layout__body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.rpv-default-layout__body::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 10px;
}

.rpv-core__viewer > div > div {
  overflow: unset !important;
}
